import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '../constants';
import { getFontFamilyStyle } from './font';
import { getElementsTimeline } from './timeline';
import { Page, RemotionConfig, TimelineElement, TrackType } from '../types';
import { getCaptionPages } from './caption';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { CaptionsPlacement, Clip, ClipMetadata } from '@/domains/asset';
import { changeCaptionsPosition } from '@/stores/clip';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export function getRemotionConfig(clip: Clip): RemotionConfig {
  const clipId = clip.id;

  const videoQuality = VIDEO_QUALITY.FULL_HD;
  const height = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].HEIGHT;
  const width = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].WIDTH;

  const fps = FPS_24;
  const durationInFrames = Math.ceil((clip.asset_metadata.config?.duration || 0) * fps);

  const fontLocation = clip.asset_metadata.font_location;
  const fontName = getFontFamilyStyle(clipId, fontLocation);
  const showCaptions = !!clip.asset_metadata.subtitle;

  const elements: TimelineElement[] = getElementsTimeline(clip);
  const pages: Page[] = getCaptionPages({
    clip
  });

  const sharedAPIStoreForClip = sharedAPI.getSnapshot()[clipId];
  const introVideoSource = clip.asset_metadata.intro;
  const drift = Math.ceil((introVideoSource ? sharedAPIStoreForClip.introPlayer?.duration || 0 : 0) * FPS_24);

  const useRemotionCaptions = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions];

  return {
    height,
    width,
    fps,
    durationInFrames,
    version: useRemotionCaptions ? '0.0.2' : '0.0.1',
    tracks: useRemotionCaptions
      ? [
          {
            name: 'Speakers',
            id: 'speakers',
            type: TrackType.SPEAKER_LABELS,
            isTrackVisible: true,
            elements
          },
          {
            name: 'Captions',
            id: 'captions',
            type: TrackType.CAPTIONS,
            isTrackVisible: showCaptions,
            pages
          }
        ]
      : [],
    elements,
    drift,
    font: {
      location: fontLocation,
      name: fontName
    },
    canResizeCaptions: false
  };
}

export function updateCaptionsPlacementInRemotionConfig({
  clipId,
  placement,
  caption_positions
}: {
  clipId: string;
  placement: CaptionsPlacement;
  caption_positions: ClipMetadata['caption_positions'];
}) {
  let x = caption_positions?.x || '2.5%';
  let y = caption_positions?.y || '82%';
  let width = caption_positions?.width || '95%';
  let height = caption_positions?.height || '18%';

  if (placement === CaptionsPlacement.Top) {
    y = 0;
  } else if (placement === CaptionsPlacement.Bottom) {
    y = 100 - parseFloat(height as string) + '%';
  } else {
    y = 50 - parseFloat(height as string) / 2 + '%';
  }

  changeCaptionsPosition({
    clipId,
    // TODO: @AshwinBhatkal make types for caption_positions standard with CSS. Right now it is fixed to number only
    caption_positions: {
      x,
      y,
      width,
      height
    } as any
  });

  EventBus.dispatch(CustomEvents.UpdateCaptionsPosition, {
    left: x,
    top: y,
    width,
    height
  });
}
