import { memo, useEffect, useState } from 'react';
import { SearchParagraph, SearchResult, TranscriptParagraph, TranscriptSelection } from '../types';
import { convertParagraphToSearchParagraph, getParagraphSearchResults } from '../utils';
import { editClipWord, mergeClipWords, saveCorrectWordChanges } from '../EditTranscriptMenu/utils';
import { getAllEditingWordOccurrences, getMergesTimeRanges } from './utils';
import IconButton from '@/components/atoms/Button/IconButton';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { Clip } from '@/domains/asset';

function TranscriptInlineEdit({
  editingTranscriptRef,
  editingContent,
  paragraphs,
  transcriptSelection,
  onCancelEditing
}: {
  editingTranscriptRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  editingContent: string;
  paragraphs: TranscriptParagraph[];
  transcriptSelection: TranscriptSelection;
  onCancelEditing: () => void;
}) {
  const { clipData } = useClipsContext();
  const [correctInputValue, setCorrectInputValue] = useState(editingContent);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (editingTranscriptRef.current)
        editingTranscriptRef.current.style.height = `${editingTranscriptRef.current?.scrollHeight}px`;
    });
  }, [editingContent]);

  function saveAndResetSelection(value: Clip) {
    saveCorrectWordChanges(value, clipData);
    onCancelEditing();
  }

  const correctTranscript = () => {
    const updatedValue =
      transcriptSelection.words.length > 1
        ? mergeClipWords(
            correctInputValue,
            clipData,
            transcriptSelection.words.at(0)!.start_time,
            transcriptSelection.words.at(-1)!.end_time
          )
        : editClipWord(transcriptSelection.words[0], correctInputValue, clipData, true);
    saveAndResetSelection(updatedValue);
  };

  const correctAll = () => {
    const content = paragraphs.map((paragraph, index) => convertParagraphToSearchParagraph(paragraph.words, index));
    const foundParagraphs: SearchResult[] = content.reduce((acc: SearchResult[], el: SearchParagraph) => {
      return [...acc, ...getParagraphSearchResults(el, editingContent)];
    }, []);
    let updatedClipValue = clipData;
    if (transcriptSelection.words.length > 1) {
      const ranges = getMergesTimeRanges(foundParagraphs);
      ranges.forEach(r => {
        updatedClipValue = mergeClipWords(correctInputValue, updatedClipValue, r.startTime, r.endTime);
      });
    } else {
      const editingWordOccurrences = getAllEditingWordOccurrences(foundParagraphs, editingContent);
      editingWordOccurrences.forEach(w => {
        updatedClipValue = editClipWord(
          w,
          w.content.replace(editingContent, correctInputValue),
          updatedClipValue,
          true
        );
      });
    }
    saveAndResetSelection(updatedClipValue);
    onCancelEditing();
  };

  return (
    <div className="relative">
      <div className="absolute bottom-full z-50 flex -translate-y-1 translate-x-[-10%] items-center space-x-0.5 rounded-lg border border-slate-300 bg-white pr-5 shadow-lg">
        <IconButton
          variation="text"
          icon="IconCheck"
          size="base"
          content="Correct"
          trackingId="correct-trascript-confirm"
          onClick={correctTranscript}
        />
        <IconButton
          variation="text"
          icon="IconChecks"
          size="base"
          content="Correct all"
          onClick={correctAll}
          trackingId="correct-all-trascript-confirm"
        />
        <IconButton
          variation="text"
          icon="IconX"
          size="base"
          content=""
          trackingId="correct-transcript-cancel"
          onClick={onCancelEditing}
        />
      </div>
      <textarea
        ref={editingTranscriptRef}
        autoFocus
        id="editing-block"
        value={correctInputValue}
        onChange={e => setCorrectInputValue(e.target.value)}
        className="block w-full resize-none overflow-hidden rounded border border-deep-orange p-1 text-black selection:bg-deep-orange focus:border-none focus:outline-none focus:ring-deep-orange"
      />
    </div>
  );
}

export default memo(TranscriptInlineEdit);
