import React, { useCallback, useEffect } from 'react';
import { AbsoluteFill, useCurrentFrame } from 'remotion';
import { Page, RemotionConfig } from '../../types';
import { SelectionOutline } from '../Outlines/SelectionOutline';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function CaptionsPageInternal({
  page,
  onOutlineRelease,
  canResizeCaptions,
  CaptionsMenu,
  drift
}: Pick<RemotionConfig, 'onOutlineRelease' | 'CaptionsMenu' | 'canResizeCaptions' | 'drift'> & {
  page: Page;
}) {
  const frame = useCurrentFrame();

  const [newPositions, setNewPositions] = React.useState({
    top: page.style.top,
    left: page.style.left,
    width: page.style.width,
    height: page.style.height
  });

  const updatePositions = useCallback(positions => {
    setNewPositions(p => ({ ...p, ...positions }));
  }, []);

  const onSelectionOutlineRelease = useCallback(
    positions => {
      onOutlineRelease?.(positions);
    },
    [onOutlineRelease]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UpdateCaptionsPosition, updatePositions);

    return () => {
      EventBus.off(CustomEvents.UpdateCaptionsPosition, eventListener);
    };
  }, [updatePositions]);

  return (
    <AbsoluteFill>
      <div style={{ ...page.style, ...newPositions }}>
        <div style={page.groupedTokensStyle}>
          {page.tokens.map(t => {
            const startRelativeToSequence = t.fromFrame - page.fromFrame;
            const endRelativeToSequence = t.toFrame - page.fromFrame;
            const frameWithDrift = frame + drift;

            const active = startRelativeToSequence <= frameWithDrift && endRelativeToSequence > frameWithDrift;

            return (
              <div key={t.fromFrame} style={active ? page.activeTokenStyle : page.inactiveTokenStyle}>
                {t.text}
              </div>
            );
          })}
        </div>
      </div>
      <SelectionOutline
        positions={{ ...newPositions }}
        updatePositions={updatePositions}
        onOutlineRelease={onSelectionOutlineRelease}
        allowResize={canResizeCaptions}
        CaptionsMenu={CaptionsMenu}
      />
    </AbsoluteFill>
  );
}
