import { useEffect, useState } from 'react';
import { AbsoluteFill, continueRender, delayRender } from 'remotion';
import { loadFont } from '@remotion/fonts';
import { RemotionConfig, TrackType } from '../types';
import MainTimelineElement from './MainTimelineElement';
import CaptionsPages from './Captions/CaptionsPages';

const Main: React.FC<RemotionConfig> = ({
  tracks,
  elements,
  font,
  drift,
  onOutlineRelease,
  canResizeCaptions,
  CaptionsMenu
}) => {
  const [handle] = useState(() => delayRender());

  useEffect(() => {
    if (font?.location && font.name) {
      loadFont({
        family: font.name,
        url: font.location
      }).then(() => {
        // eslint-disable-next-line no-console
        console.log('Font loaded - ', font.name);
        continueRender(handle);
      });
    } else {
      continueRender(handle);
    }
  }, [font?.location, font?.name]);

  if (!elements?.length && !tracks?.length) return null;

  return (
    <AbsoluteFill
      style={{
        inset: 0
      }}
      className="content-lab-root"
    >
      {tracks.length > 0
        ? tracks.map((track, index) => {
            if (!track.isTrackVisible) return null;

            if (track.type === TrackType.SPEAKER_LABELS) {
              return (
                <div key={index}>
                  {track.elements.map((element, index) => (
                    <MainTimelineElement key={`${index}_${track.id}`} element={element} drift={drift} />
                  ))}
                </div>
              );
            } else if (track.type === TrackType.CAPTIONS && track.isTrackVisible) {
              return (
                <div key={index}>
                  <CaptionsPages
                    pages={track.pages}
                    onOutlineRelease={onOutlineRelease}
                    canResizeCaptions={canResizeCaptions}
                    CaptionsMenu={CaptionsMenu}
                    drift={drift}
                  />
                </div>
              );
            }

            return null;
          })
        : elements.map((element, index) => {
            if (!element) return null;

            return <MainTimelineElement key={index} element={element} drift={drift} />;
          })}
    </AbsoluteFill>
  );
};

export default Main;
