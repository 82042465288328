import { CSSProperties } from 'react';

type CompositionOverrideProps = {
  durationInFrames?: number;
  fps?: number;
  height?: number;
  width?: number;
};

export type RemotionConfig = CompositionOverrideProps & {
  version: string;
  tracks: Track[];
  elements: TimelineElement[];
  drift: number;
  font?: {
    location?: string;
    name?: string;
  };
  // ! This will be removed with a more Robust video editor.
  onOutlineRelease?: (positions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>) => void;
  CaptionsMenu?: any;
  // to be removed with removal of feature flag
  canResizeCaptions: boolean;
};

export enum TrackType {
  SPEAKER_LABELS = 'SPEAKER_LABELS',
  CAPTIONS = 'CAPTIONS'
}

interface BaseTrack {
  name: string;
  id: string;
  type: TrackType;
  isTrackVisible: boolean;
}

export interface SpeakerLabelTrack extends BaseTrack {
  type: TrackType.SPEAKER_LABELS;
  elements: TimelineElement[];
}

export interface CaptionTrack extends BaseTrack {
  type: TrackType.CAPTIONS;
  pages: Page[];
}

export interface PageToken {
  text: string;
  fromFrame: number;
  toFrame: number;
}

export interface Page {
  text: string;
  fromFrame: number;
  toFrame: number;
  tokens: PageToken[];
  style: CSSProperties;
  groupedTokensStyle: CSSProperties;
  activeTokenStyle: CSSProperties;
  inactiveTokenStyle: CSSProperties;
}

export type Track = SpeakerLabelTrack | CaptionTrack;

export enum TimelineElementType {
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER'
}

export interface BaseTimelineElement {
  fromFrame: number;
  toFrame: number;
  style?: CSSProperties;
  type: TimelineElementType;
}

export interface TextTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.TEXT;
  content: string;
}

export interface ContainerTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.CONTAINER;
  elements?: TimelineElement[];
}

export type TimelineElement = TextTimelineElement | ContainerTimelineElement;
