import { useState, useSyncExternalStore, useMemo, memo, useCallback, useEffect } from 'react';
import {
  assetsChaptersGenerateCreate,
  ChapterResponse,
  ChapterListResponseChapterGenerationStatusEnum
} from '@goldcast/api/content';
import ChapterActionsMenu from './ChapterActionsMenu';
import { ChapterType } from './ClipTimelineTypes';
import { CHAPTER_TOOLTIP_THRESHOLD } from './ClipTimelineConstants';
import ChapterListItem from './ChapterListItem';
import { core } from '@/stores/core';
import IconButton from '@/components/atoms/Button/IconButton';
import { useAppContext } from '@/context/AppContext/AppContext';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';
import { PUSHER_STATES, PUSHER_STATE_MESSAGES } from '@/context/PusherContext/PusherContextConstants';
import { loadChapters } from '@/context/TranscriptContext/TranscriptContextUtils';
import { ChapterGenerationStatus } from '@/context/TranscriptContext/TranscriptContextTypes';

const ChaptersView = ({
  chapters,
  chapterGenerationStatus,
  totalDuration,
  zoom,
  onSelectChapter
}: {
  chapters: ChapterResponse[];
  chapterGenerationStatus: ChapterListResponseChapterGenerationStatusEnum;
  totalDuration: number;
  zoom: number;
  onSelectChapter: (time: number) => void;
}) => {
  const [isGeneratingChapters, setIsGeneratingChapters] = useState(false);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const broadcastId = useMemo(() => coreStore.content?.id, [coreStore.content]);
  const { logger } = useAppContext();
  const { updateChapter, selectedChapter, setSelectedChapter } = useTranscriptPage();

  const [dragPositions, setDragPositions] = useState({
    id: '',
    newStartTime: 0
  });

  useEffect(() => {
    if (chapterGenerationStatus === ChapterGenerationStatus.PROCESSING) {
      setIsGeneratingChapters(true);
    }
  }, [chapterGenerationStatus]);

  const updateChapterGenerationStatus = useCallback(
    async data => {
      if (!data?.state) return;

      const handleChapterGenerationDone = async () => {
        if (!broadcastId) return;

        try {
          const chapterData = await loadChapters(broadcastId);
          EventBus.dispatch(CustomEvents.UpdateChapters, chapterData?.chapters);
          showSuccessToast(PUSHER_STATE_MESSAGES[data.state]);
        } catch (error) {
          showErrorToast('Failed to load chapters. Please try again.');
        } finally {
          setIsGeneratingChapters(false);
        }
      };

      const handleChapterGenerationFailed = () => {
        showErrorToast(PUSHER_STATE_MESSAGES[data.state]);
        setIsGeneratingChapters(false);
      };

      switch (data.state) {
        case PUSHER_STATES.CHAPTER_GENERATION_DONE:
          await handleChapterGenerationDone();
          break;

        case PUSHER_STATES.CHAPTER_GENERATION_FAILED:
          handleChapterGenerationFailed();
          break;

        default:
          break;
      }
    },
    [broadcastId]
  );

  useEffect(() => {
    const ChapterEventListener = EventBus.on(
      CustomEvents.ChapterGenerationStatusUpdated,
      updateChapterGenerationStatus
    );

    return () => {
      EventBus.off(CustomEvents.ChapterGenerationStatusUpdated, ChapterEventListener);
    };
  }, [updateChapterGenerationStatus]);

  const generateChapters = async () => {
    if (!broadcastId) return;
    try {
      setIsGeneratingChapters(true);
      await assetsChaptersGenerateCreate({
        body: { content_id: broadcastId }
      } as any);
    } catch (error) {
      logger.error('Error generating chapters', error);
    }
  };

  const handleRenameChapter = (chapter: ChapterResponse) => {
    setSelectedChapter(chapter);
  };

  const handleRenameSubmit = useCallback(async () => {
    if (!selectedChapter) return;

    await updateChapter(selectedChapter.id, selectedChapter.title);
    setSelectedChapter(null);
  }, [selectedChapter]);

  const startDrag = (e: React.MouseEvent<HTMLDivElement>, selectedChapter: ChapterResponse) => {
    const targetElement = e.currentTarget as HTMLElement;
    const parentWidth = targetElement.offsetParent?.clientWidth;
    if (!parentWidth) return;

    const initialX = e.clientX;
    const initialStartTime = selectedChapter.start_time;
    let newStartTime = initialStartTime;

    const onMouseMove = (moveEvent: MouseEvent) => {
      newStartTime = Math.max(0, initialStartTime + (moveEvent.clientX - initialX));
      setDragPositions({ id: selectedChapter.id, newStartTime });
    };

    const onMouseUp = async () => {
      try {
        const updatedChapters = chapters.map(chapter =>
          chapter.id === selectedChapter.id ? { ...chapter, start_time: newStartTime } : chapter
        );
        EventBus.dispatch(CustomEvents.UpdateChapters, updatedChapters);
        await updateChapter(selectedChapter.id, undefined, newStartTime);
        setDragPositions({ id: '', newStartTime: 0 });
      } catch (error) {
        EventBus.dispatch(CustomEvents.UpdateChapters, chapters);
      } finally {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      }
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  if (!chapters || !totalDuration) return null;

  return (
    <div className=" h-6 w-full">
      {chapters.length === 0 && chapterGenerationStatus !== ChapterGenerationStatus.DONE && (
        <IconButton
          trackingId="generate-chapter"
          icon={isGeneratingChapters ? 'IconLoader2' : 'IconSparkles'}
          iconClassName={isGeneratingChapters ? 'animate-spin' : ''}
          size="small"
          disabled={isGeneratingChapters}
          buttonClassName="h-6 bg-slate-100 btn-text"
          onClick={generateChapters}
          content={isGeneratingChapters ? 'Generating Chapters' : 'Generate Chapters'}
        />
      )}
      {chapters.length > 0 &&
        chapters.map((chapter: ChapterResponse, index: number) => {
          const dragPosition = dragPositions.id === chapter.id ? dragPositions : null;
          const currentStartTime = dragPosition ? dragPosition.newStartTime : chapter.start_time;
          const nextStartTime = chapters[index + 1]?.start_time || totalDuration;
          const widthPercentage = ((nextStartTime - currentStartTime) / totalDuration) * 100;

          return (
            <div
              key={chapter.id}
              className="group absolute flex h-6 items-center rounded-md pr-6 hover:bg-slate-100"
              style={{
                left: `${(currentStartTime / totalDuration) * 100}%`,
                width: `${widthPercentage}%`
              }}
              onClick={() => onSelectChapter(currentStartTime)}
            >
              <div
                className="absolute -left-1 z-20 h-full w-2 cursor-ew-resize"
                onMouseDown={e => {
                  e.stopPropagation();
                  startDrag(e, chapter);
                }}
              ></div>
              <div className="absolute -left-[1px] h-3 w-[1.5px] shrink-0 rounded bg-black"></div>
              {widthPercentage * zoom > CHAPTER_TOOLTIP_THRESHOLD && (
                <div className="absolute right-1 z-50">
                  <div className="flex h-[18px] w-[18px] items-center justify-center rounded pt-[3px] opacity-0 hover:bg-slate-200 group-hover:opacity-100">
                    <ChapterActionsMenu
                      chapterId={chapter.id}
                      type={ChapterType.TIMELINE}
                      chapterStartTime={currentStartTime}
                      chapterEndTime={nextStartTime}
                      onRenameChapter={() => handleRenameChapter(chapter)}
                    />
                  </div>
                </div>
              )}
              <ChapterListItem
                chapter={chapter}
                selectedChapter={selectedChapter}
                setSelectedChapter={setSelectedChapter}
                handleRenameSubmit={handleRenameSubmit}
              />
            </div>
          );
        })}
    </div>
  );
};

export default memo(ChaptersView);
