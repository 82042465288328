import { Page, RemotionConfig } from '../../types';
import CaptionsPage from './CaptionsPage';

export default function CaptionsPages({
  pages,
  onOutlineRelease,
  CaptionsMenu,
  canResizeCaptions,
  drift
}: Pick<RemotionConfig, 'onOutlineRelease' | 'CaptionsMenu' | 'canResizeCaptions' | 'drift'> & {
  pages: Page[];
}) {
  return pages.map((page, index) => (
    <CaptionsPage
      key={index}
      page={page}
      onOutlineRelease={onOutlineRelease}
      canResizeCaptions={canResizeCaptions}
      CaptionsMenu={CaptionsMenu}
      drift={drift}
    />
  ));
}
