import { memo, useMemo } from 'react';
import moment from 'moment';
import IconButton from '../../atoms/Button/IconButton';
import RecordingPublishDialog from './RecordingPublishDialog';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import useDialog from '@/components/organisms/useDialog';
import { ClipPublishedStatus } from '@/domains/asset';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import TooltipV2 from '@/components/TooltipV2';

function RecordingPublishButton() {
  const { clipData } = useClipsContext();
  const { isOpen: isPublishDialogOpen, openDialog: openPublishDialog, closeDialog: closePublishDialog } = useDialog();

  const isPublishedWithLatestChanges = useMemo(() => {
    return (
      clipData.asset_metadata.publish_status === ClipPublishedStatus.PUBLISHED &&
      moment(clipData.updated_at).diff(moment(clipData.asset_metadata.published_at), 'second') < 10
    );
  }, [clipData.asset_metadata.publish_status, clipData.asset_metadata.published_at, clipData.updated_at]);

  const isClipInInitialState = useMemo(() => {
    return moment(clipData.updated_at).isSame(moment(clipData.asset_metadata.created_at), 'minute');
  }, [clipData.asset_metadata.created_at, clipData.updated_at]);

  const isPublishDisabled = useMemo(
    () => isPublishedWithLatestChanges || isClipInInitialState,
    [isPublishedWithLatestChanges, isClipInInitialState]
  );

  return (
    <>
      <TooltipV2
        place="bottom"
        tooltipId="publish-tooltip"
        tooltipContent={isPublishDisabled ? 'There are no unpublished changes' : ''}
        size="xsmall"
        tooltipElement={
          <IconButton
            icon="IconArrowBarToUp"
            content="Publish"
            variation="filled"
            trackingId="publish-recording-button"
            disabled={isPublishDisabled}
            onClick={openPublishDialog}
            data-tooltip-id="publish-tooltip"
          />
        }
      />

      <ContentDialog
        isOpen={isPublishDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closePublishDialog}
        disableBackdropClose={false}
      >
        <RecordingPublishDialog onClose={closePublishDialog} />
      </ContentDialog>
    </>
  );
}

export default memo(RecordingPublishButton);
