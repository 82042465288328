import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { AbsoluteFill, useCurrentScale } from 'remotion';
import { SELECTION_OUTLINE_COLOR } from '../../constants';
import { ResizeHandle } from './ResizeHandle';
import { RemotionConfig } from '../../types';

export const SelectionOutline: React.FC<{
  positions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>;
  updatePositions: (newPositions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>) => void;
  onOutlineRelease: (newPositions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>) => void;
  allowResize: boolean;
  CaptionsMenu: RemotionConfig['CaptionsMenu'];
}> = ({ positions, updatePositions, onOutlineRelease, allowResize, CaptionsMenu }) => {
  const scale = useCurrentScale();
  const scaledBorder = Math.ceil(2 / scale);
  const outlineRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleDocumentPointerDown = () => {
      setIsSelected(false);
    };

    document.addEventListener('pointerdown', handleDocumentPointerDown);

    return () => {
      document.removeEventListener('pointerdown', handleDocumentPointerDown);
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const style: React.CSSProperties = useMemo(() => {
    const shouldShowOutline = (isHovered && !isDragging) || isSelected;

    return {
      width: positions.width,
      height: positions.height,
      left: positions.left,
      top: positions.top,
      position: 'absolute',
      outline: shouldShowOutline ? `${scaledBorder}px solid ${SELECTION_OUTLINE_COLOR}` : undefined,
      cursor: isDragging ? 'grabbing' : shouldShowOutline ? 'grab' : 'pointer',
      userSelect: 'none',
      touchAction: 'none'
    };
  }, [positions, isHovered, isDragging, isSelected, scaledBorder]);

  const startDragging = useCallback(
    (e: PointerEvent | React.MouseEvent) => {
      const initialX = e.clientX;
      const initialY = e.clientY;

      const parentDimensions = outlineRef.current?.parentElement?.getBoundingClientRect();
      const parentHeight = parentDimensions?.height || 0;
      const parentWidth = parentDimensions?.width || 0;

      let top = positions.top;
      let left = positions.left;

      const onPointerMove = (pointerMoveEvent: PointerEvent) => {
        setIsDragging(true);
        const offsetY = pointerMoveEvent.clientY - initialY;
        const offsetX = pointerMoveEvent.clientX - initialX;

        const maxTop = 100 - parseFloat(positions.height as string);
        const maxLeft = 100 - parseFloat(positions.width as string);

        const relativeChangeInX = (offsetX / parentWidth) * 100;
        const relativeChangeInY = (offsetY / parentHeight) * 100;

        top = `${Math.max(0, Math.min(parseFloat(positions.top as string) + relativeChangeInY, maxTop))}%`;
        left = `${Math.max(0, Math.min(parseFloat(positions.left as string) + relativeChangeInX, maxLeft))}%`;

        updatePositions({
          top,
          left
        });
      };

      const onPointerUp = () => {
        setIsDragging(false);
        onOutlineRelease({ ...positions, top, left });
        window.removeEventListener('pointermove', onPointerMove);
      };

      window.addEventListener('pointermove', onPointerMove, { passive: true });

      window.addEventListener('pointerup', onPointerUp, {
        once: true
      });
    },
    [positions, updatePositions]
  );

  const onPointerDown = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (e.button !== 0) {
        return;
      }

      setIsSelected(true);
      startDragging(e);
    },

    [setIsSelected, startDragging]
  );

  return (
    <AbsoluteFill
      onPointerDown={onPointerDown}
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
      style={style}
      ref={outlineRef}
    >
      {isSelected && createPortal(<CaptionsMenu />, document.getElementById('player-main-section'))}
      {allowResize && isSelected && (
        <>
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="top"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="top-right"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="right"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="bottom-right"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="bottom"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="bottom-left"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="left"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
          <ResizeHandle
            positions={positions}
            updatePositions={updatePositions}
            type="top-left"
            outlineRef={outlineRef}
            setIsDragging={setIsDragging}
            onHandleRelease={onOutlineRelease}
          />
        </>
      )}
    </AbsoluteFill>
  );
};
